import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '#/store/actions'
import { selectAuthUserId } from '#/store/selectors'
import { SuspenseAtom } from '../atoms/RouteLoading.atom'
import { NotifcationsContainer } from '../containers/Notifications.container'
import { STORAGE_TOKEN_KEY } from '#/config'

const PublicRoutes = React.lazy(() => import('./PublicRoutes.page'))
const PrivateRoutesPage = React.lazy(() => import('./PrivateRoutes.page'))


const savedToken = sessionStorage.getItem(STORAGE_TOKEN_KEY) ?? ''

export const MainPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (savedToken) {
      dispatch(authActions.receiveToken(savedToken))
    }
  }, [dispatch])

  const authUserId = useSelector(selectAuthUserId)
  return <SuspenseAtom>
        {authUserId ? <PrivateRoutesPage /> : <PublicRoutes />}
        <NotifcationsContainer />
    </SuspenseAtom>
}
