import { TStore } from '#/types'
import { createSelector } from 'reselect'

export const selectQueryParams = () => new URLSearchParams(document.location.search)

export const selectQueryParamsObject = createSelector(
  selectQueryParams,
  (params) => Object.fromEntries<string>(params)
)
export const selectQueryParam = (param: string) => createSelector(
  selectQueryParams,
  params => params.get(param)
)

export const selectRouterState = (store: TStore) => store.router.location.state || {}

export const getRouterBreadcrumbs = createSelector(
  selectRouterState,
  state => 
  state.breadcrumbs || []
)