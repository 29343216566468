import { TNotification, TNotificationsByKey } from '../../../types';
import { createReducer } from '@reduxjs/toolkit';
import { reduceItem } from '#/utils';
import { notificationActions } from '#/store/actions';

export default createReducer<TNotificationsByKey>({}, {
  [notificationActions.OPEN]: reduceItem<TNotification>('key'),
  [notificationActions.CLEAR]: (state, { payload }) => {
    const newState = {...state}
    delete newState[`${payload}`]
    return newState
  }
})