import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '../../config/api'
import { nearEventsActions } from '../actions'
import { TSaga } from '#/types';

function* watchFetch(): TSaga {
  const { data } = yield call(api.get, '/feed')
  yield put(nearEventsActions.receive(data.friends || []))
}

export function* nearEventsSagas(): TSaga {
  yield takeLatest(nearEventsActions.FETCH, watchFetch)
}