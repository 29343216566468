import { ArgsProps } from 'antd/lib/message'

const actionTypes = {
  OPEN: 'NOTIFICATION/OPEN',
  CLEAR: 'NOTIFICATION/CLEAR',
}

export const notificationActions = {
  ...actionTypes,
  open: (payload: ArgsProps) => ({
    payload: {
      ...payload,
      key: payload.key || new Date().getTime().toString()
    },
    type: actionTypes.OPEN,
  }),
  clear: (key: string) => ({
    payload: key,
    type: actionTypes.CLEAR
  })
}