import type { TFriendEntity, TFriendshipStatus } from '../../types'

const actionTypes = {
  ADD: 'FRIENDS/ADD',
  APPROVE: 'FRIENDS/APPROVE',
  APPROVED: 'FRIENDS/APPROVED',
  FETCH: 'FRIENDS/FETCH',
  INVITES_COUNT_FETCH: 'FRIENDS/INVITES_COUNT_FETCH',
  INVITES_COUNT_RECEIVE: 'FRIENDS/INVITES_COUNT_RECEIVE',
  RECEIVE: 'FRIENDS/RECEIVE',
  RECEIVE_ITEM: 'FRIENDS/RECEIVE_ITEM',
  REMOVE: 'FRIENDS/REMOVE',
  REMOVE_SUCCESS: 'FRIENDS/REMOVE_SUCCESS',
};

export const friendActions = {
  ...actionTypes,
  add: (payload: number) => ({
    payload,
    type: actionTypes.ADD,
  }),
  approve: (payload: number) => ({
    payload,
    type: actionTypes.APPROVE,
  }),
  approved: (payload: TFriendEntity) => ({
    payload,
    type: actionTypes.APPROVED
  }),
  fetch: (payload: TFriendshipStatus) => ({
    payload,
    type: actionTypes.FETCH,
  }),
  invitesCountFetch: () => ({
    type: actionTypes.INVITES_COUNT_FETCH,
  }),
  invitesCountReceive: (payload: number) => ({
    payload,
    type: actionTypes.INVITES_COUNT_RECEIVE
  }),
  receive: (payload: TFriendEntity[]) => ({
    payload,
    type: actionTypes.RECEIVE
  }),
  receiveItem: (payload: TFriendEntity) => ({
    payload,
    type: actionTypes.RECEIVE_ITEM
  }),
  remove: (payload: number) => ({
    payload,
    type: actionTypes.REMOVE
  }),
  removeSuccess: (payload: number) => ({
    payload,
    type: actionTypes.REMOVE_SUCCESS
  })
}