import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import rootReducer from './reducers'
import sagas from './sagas'
import { TLocationState } from '#/types'

export const history = createBrowserHistory<TLocationState>()
const sagaMiddleware = createSagaMiddleware()

export function configureStore(defaultState = {}) {
  // @ts-ignore
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    rootReducer(history),
    defaultState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware
      ),
    ),
  )

  sagaMiddleware.run(sagas)

  // @ts-ignore Hot reloading
  if (module.hot) {
    // @ts-ignore Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducer(history))
    })
  }

  return store
}

const store = configureStore();

export default store;