import { TLocale } from '#/types'
import { LOCALES_AVAILABLE, LOCALE_STORAGE_KEY } from '../config'

export const getDefaultLocale = (): TLocale => {
  let browserLanguage = 'ru'
  if (navigator && navigator.language) {
    const possibleLanguage = navigator.language.slice(0, 2)
    if (LOCALES_AVAILABLE.includes(possibleLanguage)) {
      browserLanguage = possibleLanguage
    }
  }
  return localStorage.getItem(LOCALE_STORAGE_KEY) as TLocale || browserLanguage
}