import { call, put, takeLatest } from 'redux-saga/effects'
import { authActions, loginActions, notificationActions } from '#/store/actions'
import { api, apiAuth } from '#/config'
import { t } from 'i18n-js'
import { TSaga } from '#/types';


function* watchLogin({ payload }: ReturnType<typeof loginActions.submit>): TSaga {
  const { data } = yield call(apiAuth.post, '/login', payload);
  if (data.access_token)
    yield put(authActions.receiveToken(data.access_token));
}

function* watchForgotPass({ payload }: ReturnType<typeof loginActions.forgotPass>): TSaga {
  yield call(api.post, '/forgot-pass', payload)
  yield put(notificationActions.open({
    type: 'success',
    content: t('forgotPass.message')
  }))
}

function* watchConfirm({ payload }: ReturnType<typeof loginActions.confirm>): TSaga {
  const { data } = yield call(apiAuth.get, payload)
  if (data.access_token) {
    yield put(authActions.receiveToken(data.access_token));
    yield put(notificationActions.open({
      type: 'success',
      content: t('confirmed')
    }))
  }
}

export function* loginSagas(): TSaga {
  yield takeLatest(loginActions.SUBMIT, watchLogin)
  yield takeLatest(loginActions.FORGOT_PASS, watchForgotPass)
  yield takeLatest(loginActions.CONFIRM, watchConfirm)
}