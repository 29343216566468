import { reduceItem, reduceList } from '#/utils';
import { createReducer } from '@reduxjs/toolkit';
import { TFriendEntity, TFriendsById } from '../../../types';
import { friendActions } from '../../actions'

export default createReducer<TFriendsById>({}, {
  [friendActions.RECEIVE]: reduceList<TFriendEntity>('id'),
  [friendActions.RECEIVE_ITEM]: reduceItem<TFriendEntity>('id'),
  [friendActions.APPROVED]: (state, { payload }) => {
    const newState = {...state}
    delete newState[payload.id]
    return newState
  },
  [friendActions.REMOVE_SUCCESS]: (state, { payload }) => {
    const newState = {...state}
    delete newState[payload]
    return newState
  },
});