type TAction<T> = {
    type: string,
    payload: T
}

export const reduceItem = <T>(key: keyof T) => (state: Record<string, T> , { payload }: TAction<T>): Record<string, T> => ({
    ...state,
    [`${payload[key]}`]: payload
})
  
export const reduceItemById = reduceItem('id')

export const reduceList =  <T>(key: keyof T) => (state: Record<string, T> , { payload }: TAction<T[]>): Record<string, T> => ({
    ...state,
    ...payload.reduce<Record<string, T>>((res, item) => {
        const index = item[key]
        return {
        ...res,
        [`${index}`]: item
    }}, {})
})

export const reduceListById = reduceList('id')

export const reduceListKeys = <T>(key: keyof T) => (state: string[], { payload }: TAction<T[]>) => payload.map((item: T) => `${item[key]}`)

export const reduceByPayloadKey = <T>(key: keyof T) =>(state: string[], { payload }: TAction<T>) => state.filter(item => item !== `${payload[key]}`)

export const reduceByPayload = (state: string[], { payload }: TAction<string>) => state.filter(item => item != payload)

export const reduceIds = reduceListKeys('id')
export const reduceByPayloadId = reduceByPayloadKey('id')