import type { TLocale } from '../../types'

const actionTypes = {
  SET: 'LOCALE/SET',
}

export const localeActions = {
  ...actionTypes,
  set: (payload: TLocale) => ({
    payload,
    type: actionTypes.SET
  }),
}