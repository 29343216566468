import { call, put, takeEvery } from 'redux-saga/effects'
import { authActions, notificationActions, profileSettingsActions } from '#/store/actions'
import { api } from '#/config'
import { t } from 'i18n-js'
import { TSaga } from '#/types';

function* watchFetch(): TSaga {
  const { data: { settings} } = yield call(api.get, '/settings')
  yield put(profileSettingsActions.receive(settings))
}

function* watchFetchPrivate(): TSaga {
  const { data: { settings} } = yield call(api.get, '/settings/1')
  yield put(profileSettingsActions.receivePrivate(settings))
}

function* watchUpdate({ payload }: ReturnType<typeof profileSettingsActions.update>): TSaga {
  const { data: { settings} } = yield call(api.post, '/settings', payload)
  yield put(profileSettingsActions.receive(settings))
  yield put(notificationActions.open({
    type: 'success',
    content: t('updated'),
  }))
}

export function* profileSettingsSagas(): TSaga {
  yield takeEvery(profileSettingsActions.FETCH, watchFetch)
  yield takeEvery([
    profileSettingsActions.FETCH_PRIVATE,
    authActions.RECEIVE_TOKEN
  ], watchFetchPrivate)
  yield takeEvery(profileSettingsActions.UPDATE, watchUpdate)
}