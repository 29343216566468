import { TProfile, TProfileEntity } from '../../types';

const actionTypes = {
  UPDATE: 'PROFILE/UPDATE',
  UPDATE_SUCCESS: 'PROFILE/UPDATE_SUCCESS',
}

export const profileActions = {
  ...actionTypes,
  update: (payload: TProfile) => ({
    payload,
    type: actionTypes.UPDATE
  }),
  updateSuccess: (payload: TProfileEntity) => ({
    payload,
    type: actionTypes.UPDATE_SUCCESS
  }),
}