import { call, put, takeLatest } from 'redux-saga/effects'
import { usersActions } from '#/store/actions';
import { api } from '#/config'
import { TSaga } from '#/types';

function* watchFetchList({ payload }: ReturnType<typeof usersActions.fetchList>): TSaga {
  const { data } = yield call(api.post, '/profile-by-ids', { ids: payload })
  yield put(usersActions.receiveList(data.users))
}

export function* usersSagas(): TSaga {
  yield takeLatest(usersActions.FETCH_LIST, watchFetchList)
}