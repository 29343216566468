import { createReducer } from '@reduxjs/toolkit'
import { authActions, friendActions, profileActions, usersActions } from '#/store/actions'
import { reduceItemById, reduceListById } from '#/utils'
import { TProfileEntity } from '#/types';

export type TProfileByIdStore = Record<string, TProfileEntity>

export default createReducer({}, {
  [authActions.RECEIVE]: reduceItemById,
  [usersActions.RECEIVE_ITEM]: reduceItemById,
  [profileActions.UPDATE_SUCCESS]: reduceItemById,
  [usersActions.RECEIVE_LIST]: reduceListById,
  [friendActions.RECEIVE]: (state: TProfileByIdStore, { payload }: ReturnType<typeof friendActions.receive>): TProfileByIdStore => ({
    ...state,
    ...payload.reduce((result, { user }) => ({
      ...result,
      [user.id]: user
    }), {})
  })
})