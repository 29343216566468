import { selectNotifications } from '#/store/selectors';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { Notifcation } from '../atoms/Notification.atom';

export const NotifcationsContainer = () => {
  const notifications = useSelector(selectNotifications)
  const [messageApi, contextHolder] = message.useMessage();

  return <>
    {contextHolder}
    {notifications.map(n => <Notifcation
        notification={n}
        messageApi={messageApi}
        key={n.key}
    />)}
  </>;
}