import { get } from 'lodash'
import { generatePath } from 'react-router'
import { COMPONENT_URLS } from '#/config'

export const getRoute = (path: string, opts: any = {}): string => {
  const urlTemplate = get(COMPONENT_URLS, path)
  if (!urlTemplate) {
    throw Error(`No url template found by "${path}"`)
  }
  return generatePath(urlTemplate, opts)
}