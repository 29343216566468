import { createSelector } from '@reduxjs/toolkit'
import { selectQueryParam } from './router.selectors'
import { TFriendsById, TProfileShort, TStore } from '../../types';

export const selectFriendsIds = (state: TStore): string[] => state.friends.ids
export const selectFriendsById = (state: TStore): TFriendsById => state.friends.byId

export const selectFriendsInvitesCount = (state: TStore): number => state.friends.invitesCount

const searchFields: Array<keyof Omit<TProfileShort, 'id'>> = ['first_name', 'last_name', 'nick_name']

export const getFriends = createSelector(
  [selectFriendsIds, selectFriendsById, selectQueryParam('q')],
  (ids, byId, q) => ids
    .map( (id: string) => byId[id])
    .filter( u => {
      if (!q) return true
      return searchFields.some(field => u.user[field].includes(q))
    })
)