import { combineReducers } from 'redux';
import { History } from 'history'
import { connectRouter } from 'connected-react-router'
import auth from './auth.reducers'
import breadcrumbs from './breadcrumbs.reducers'
import friends from './friends'
import profile from './profile'
import locale from './locale.reducers'
import nearEvents from './nearEvents.reducer'
import wishes from './wishes'
import users from './users'
import notifications from './notifications'
import { TStore, TLocationState } from '../../types';

const store = (history: History<TLocationState>) => combineReducers<TStore>({
  auth,
  breadcrumbs,
  friends,
  locale,
  nearEvents,
  notifications,
  profile,
  router: connectRouter(history),
  users,
  wishes,
})

export default store