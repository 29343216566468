import { createReducer } from '@reduxjs/toolkit';
import { wishActions } from '../../actions'
import { TWishesStore } from '../../../types';
import { reduceIds, reduceByPayload } from '#/utils';

export default createReducer<TWishesStore['ids']>([], {
  [wishActions.RECEIVE_LIST]: reduceIds,
  [wishActions.CREATE_SUCCESS]: (state, { payload }) => {
    const idString = `${payload.id}`
    return [...state.filter( id => id !== idString), idString]
  },
  [wishActions.DELETE]: reduceByPayload
})