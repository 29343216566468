import { getRoute } from '#/utils';
import { createSelector } from '@reduxjs/toolkit';
import { TStore } from '../../types';

export const selectAuthUserId = (state: TStore) => state.auth

export const selectUserWishesURL = createSelector(
    selectAuthUserId,
    userId => userId ? getRoute('WISHES.OF_USER', { userId }) : null
  
)