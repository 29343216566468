import { TChildrenProps } from "#/types"
import { Skeleton } from "antd"
import { Suspense } from "react"
import { Route, RouteProps } from "react-router"


export const SuspenseAtom = (props: TChildrenProps) => <Suspense fallback={<Skeleton active />} {...props} />

export const RouteSuspensed = ({children, ...props}: RouteProps & TChildrenProps) => <Route {...props}>
    <SuspenseAtom>
        {children}
    </SuspenseAtom>
</Route>