import { TProfileShort } from '#/types';

const actionTypes = {
  SEARCH: 'FRIENDS/SEARCH',
  SEARCH_SUCCESS: 'FRIENDS/SEARCH_SUCCESS',
}

export const friendsSearchActions = {
  ...actionTypes,
  search: (payload: string) => ({
    payload,
    type: actionTypes.SEARCH
  }),
  searchSuccess: (payload: TProfileShort[]) => ({
    payload,
    type: actionTypes.SEARCH_SUCCESS
  })
}