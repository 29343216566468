import { TForgotPassPayload } from "#/types";

export type TLoginPayload = {
  password: string,
  username: string
};

const actionTypes = {
  CONFIRM: 'LOGIN/CONFIRM',
  FORGOT_PASS: 'LOGIN/FORGOT_PASS',
  LOGOUT: 'LOGOUT',
  SUBMIT: 'LOGIN/SUBMIT',
}

export const loginActions = {
  ...actionTypes,
  confirm: (payload: string) => ({
    payload,
    type: actionTypes.CONFIRM,
  }),
  forgotPass: (payload: TForgotPassPayload) => ({
    payload,
    type: actionTypes.FORGOT_PASS,
  }),
  logout: () => ({
    type: actionTypes.LOGOUT
  }),
  submit: (payload: TLoginPayload) => ({
    payload,
    type: actionTypes.SUBMIT
  }),
}