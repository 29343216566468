import { TProfileEntity } from '#/types';

const actionTypes = {
  FETCH_LIST: 'USERS/FETCH_LIST',
  RECEIVE_ITEM: 'USERS/RECEIVE_ITEM',
  RECEIVE_LIST: 'USERS/RECEIVE_LIST',
}

export const usersActions = {
  ...actionTypes,
  fetchList: (payload: number[]) => ({
    payload,
    type: actionTypes.FETCH_LIST
  }),
  receiveItem: (payload: TProfileEntity) => ({
    payload,
    type: actionTypes.RECEIVE_ITEM
  }),
  receiveList: (payload: TProfileEntity[]) => ({
    payload,
    type: actionTypes.RECEIVE_LIST
  }),
}