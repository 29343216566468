import { wishActions } from '../../actions'
import { TWishEntity, TWishesById } from '../../../types';
import { createReducer } from '@reduxjs/toolkit';
import { reduceItem, reduceList } from '#/utils';

export default createReducer<TWishesById>({}, {
  [wishActions.RECEIVE_LIST]: reduceList<TWishEntity>('id'),
  [wishActions.RECEIVE_ITEM]: reduceItem<TWishEntity>('id'),
  [wishActions.UPDATE_SUCCESS]: reduceItem<TWishEntity>('id'),
  [wishActions.CREATE_SUCCESS]: reduceItem<TWishEntity>('id'),
  [wishActions.DELETE]: (state, { payload }) => {
    const newState = {...state}
    delete newState[`${payload}`]
    return newState
  }
})