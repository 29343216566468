import { call, put, takeLatest } from 'redux-saga/effects'
import { friendActions, notificationActions, } from '#/store/actions';
import { api } from '#/config'
import { t } from 'i18n-js'
import { TSaga } from '#/types';

function* watchAdd({ payload }: ReturnType<typeof friendActions.add>): TSaga {
  const { data } = yield call(api.put, `/friend/${payload}`)
  yield put(friendActions.receiveItem(data.friend))
  yield put(notificationActions.open({ type: 'success', content: t('friends.requestSent') }))
}
function* watchApprove({ payload }: ReturnType<typeof friendActions.approve>): TSaga {
  const { data } = yield call(api.get, `/friend/approve/${payload}`)
  yield put(friendActions.approved(data.friend))
  yield put(notificationActions.open({ 
    type: 'success',
    content: t('friends.requestApproved')
  }))
}
function* watchRemove({ payload }: ReturnType<typeof friendActions.remove>): TSaga {
  const { data } = yield call(api.delete, `/friend/${payload}`)
  yield put(friendActions.removeSuccess(data.friendShipId))
  yield put(notificationActions.open({
    type: 'success',
    content: t('friends.friendshipCancelled')
  }))
}

function* watchFetch({ payload }: ReturnType<typeof friendActions.fetch>): TSaga {
  const { data } = yield call(api.get, `/friends/${payload}`)
  yield put(friendActions.receive(data.friends))
}
function* watchFetchInvitesCount(): TSaga {
  const { data } = yield call(api.get, '/friends/invites-count')
  yield put(friendActions.invitesCountReceive(data.count))
}

export function* friendsSagas(): TSaga {
  yield takeLatest(friendActions.FETCH, watchFetch)
  yield takeLatest(friendActions.ADD, watchAdd)
  yield takeLatest(friendActions.REMOVE, watchRemove)
  yield takeLatest(friendActions.APPROVE, watchApprove)
  yield takeLatest(
    [friendActions.INVITES_COUNT_FETCH, friendActions.APPROVED, friendActions.REMOVE_SUCCESS],
    watchFetchInvitesCount
  )
}