import { Provider } from "react-redux";
import { PushReplaceHistory, QueryParamProvider } from "use-query-params";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router'
import "#/theme/less/antd.less";
import store, { history } from "./store";
import { MainPage } from "./components/pages/MainPage";

const queryClient = new QueryClient();

export const App = () => <Provider store={store}>
    <BrowserRouter>
      <ConnectedRouter history={history}>
        <QueryParamProvider history={history as unknown as PushReplaceHistory}>
          <QueryClientProvider client={queryClient}>
            <MainPage />
          </QueryClientProvider>
        </QueryParamProvider>
      </ConnectedRouter>
    </BrowserRouter>
  </Provider>;