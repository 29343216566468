import { TProfileSettings } from '../../types';

const actionTypes = {
  FETCH: 'PROFILE_SETTINGS/FETCH',
  RECEIVE: 'PROFILE_SETTINGS/RECEIVE',
  FETCH_PRIVATE: 'PROFILE_SETTINGS/FETCH_PRIVATE',
  RECEIVE_PRIVATE: 'PROFILE_SETTINGS/RECEIVE_PRIVATE',
  UPDATE: 'PROFILE_SETTINGS/UPDATE',
}

export const profileSettingsActions = {
  ...actionTypes,
  fetch: () => ({
    type: actionTypes.FETCH
  }),
  receive: (payload: TProfileSettings) => ({
    payload,
    type: actionTypes.RECEIVE
  }),
  fetchPrivate: () => ({
    type: actionTypes.FETCH_PRIVATE
  }),
  receivePrivate: (payload: TProfileSettings) => ({
    payload,
    type: actionTypes.RECEIVE_PRIVATE
  }),
  update: (payload: TProfileSettings) => ({
    payload,
    type: actionTypes.UPDATE
  }),
}