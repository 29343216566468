import { combineReducers } from 'redux'
import { TFriendsStore } from '../../../types';
import ids from './ids.reducer'
import byId from './byId.reducer'
import search from './friendsSearch.reducer'
import invitesCount from './invitesCount.reducer'

export default combineReducers<TFriendsStore>({
  byId,
  ids,
  invitesCount,
  search,
})