import { combineReducers } from 'redux'
import byId from './byId.reducer'
import ids from './ids.reducer'
import { TWishesStore } from '../../../types';

const reducer = combineReducers<TWishesStore>({
  byId,
  ids
})

export default reducer