import { TUpcomingEventEntity } from '../../types';

const actionTypes = {
  FETCH: 'UPCOMING_EVENTS/FETCH',
  RECEIVE: 'UPCOMING_EVENTS/RECEIVE',
}

export const nearEventsActions = {
  ...actionTypes,
  fetch: () => ({
    type: actionTypes.FETCH
  }),
  receive: (payload: TUpcomingEventEntity[]) => ({
    payload,
    type: actionTypes.RECEIVE
  }),
}