import { TStore, TWishEntity } from '#/types';
import { createSelector } from '@reduxjs/toolkit'
import { createByKeySelector } from './base.selectors';
import { selectQueryParamsObject } from './router.selectors'

export const selectWishesIds = (state: TStore) => state.wishes.ids
export const selectWishesById = (state: TStore) => state.wishes.byId

export const getWishes = createSelector(
  [selectWishesIds, selectWishesById],
  (ids, byId): TWishEntity[] => ids.map(id => byId[`${id}`])
)

export const getWishById = createByKeySelector(selectWishesById)

export const getWishesFiltered = createSelector(
  [getWishes, selectQueryParamsObject],
  (wishes: TWishEntity[], filter) => {
    
    const notEmptyFilterKeys = Object.keys(filter)
      .filter(filterKey => !['', undefined].includes(filter[filterKey]))
    
      return wishes.filter(wish => notEmptyFilterKeys.every(filterKey =>
      // double equal since we compare 0 and false
      (filterKey === 'q') ? wish.description.includes(filter[filterKey]) : wish[filterKey as keyof TWishEntity] == filter[filterKey]
    ))
  })