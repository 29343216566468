import { TProfileEntity } from '../../types';

const actionTypes = {
  CLEAR: 'AUTH/CLEAR',
  FETCH: 'AUTH/FETCH',
  RECEIVE: 'AUTH/RECEIVE',
  RECEIVE_TOKEN: 'AUTH/RECEIVE_TOKEN',
}

export const authActions = {
  ...actionTypes,
  clear: () => ({
    type: actionTypes.CLEAR
  }),
  fetch: () => ({
    type: actionTypes.FETCH
  }),
  receive: (payload: TProfileEntity) => ({
    payload,
    type: actionTypes.RECEIVE
  }),
  receiveToken: (payload: string) => ({
    payload,
    type: actionTypes.RECEIVE_TOKEN
  }),
}