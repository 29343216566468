import { TBreadcrumb } from '#/types'

const actionTypes = {
  SET: 'BREADCRUMBS/SET',
}

export const breadcrumbsActions = {
  ...actionTypes,
  set: (payload: Array<TBreadcrumb>) => ({
    payload,
    type: actionTypes.SET
  }),
}