import { call, put, select, takeEvery } from 'redux-saga/effects'
import { localeActions, profileSettingsActions } from '#/store/actions'
import { api, LOCALE_STORAGE_KEY } from '#/config'
import { TLocale, TSaga } from '#/types';
import { selectAuthUserId } from '../selectors'

function* watchLangChange({ payload }: ReturnType<typeof localeActions.set>): TSaga {
  localStorage.setItem(LOCALE_STORAGE_KEY, payload)
  api.defaults.headers.common['accept-language'] = payload
  const userId = yield select(selectAuthUserId)
  if (!userId) return
  yield call(api.post, '/settings', { LANG: payload })
}

function* watchLangReceiveFromProfile({ payload }: ReturnType<typeof profileSettingsActions.receivePrivate>): TSaga {
  if (!payload.LANG) return
  const storageLocale = localStorage.getItem(LOCALE_STORAGE_KEY) as TLocale
  // locale was changed before login, let's bind it to profile then
  if (storageLocale && storageLocale !== payload.LANG) 
    yield put(localeActions.set(storageLocale))
  // there was no locale selected but profile has own
  if (!storageLocale)
    yield put(localeActions.set(payload.LANG as TLocale))
}

export function* localeSagas(): TSaga {
  yield takeEvery(localeActions.SET, watchLangChange)
  yield takeEvery(profileSettingsActions.RECEIVE_PRIVATE, watchLangReceiveFromProfile)
}