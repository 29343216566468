import {
  notificationActions,
  usersActions,
  wishActions,
} from '#/store/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { api, COMPONENT_URLS } from '#/config'
import { getRoute, translateEntity } from '#/utils'
import { selectAuthUserId } from '#/store/selectors'
import { history } from '#/store'
import { TSaga } from '#/types';

function* watchFetchList({ payload }: ReturnType<typeof wishActions.fetchList>): TSaga {
  const url = payload.userId ? `/wishes-of/${payload.userId}` : '/wishes'
  const { data } = yield call(api.get, url)
  yield put(wishActions.receiveList(data.wishes || []))
  if (data.user) {
    yield put(usersActions.receiveItem(data.user))
  }
}

function* watchFetchItem({ payload }: ReturnType<typeof wishActions.fetchItem>): TSaga {
  const { data } = yield call(api.get, `/wishes/${payload}`)
  yield put(wishActions.receiveItem(data.wish))
}
function* watchCreate({ payload }: ReturnType<typeof wishActions.fetchItem>): TSaga {
  const { data } = yield call(api.post, '/wishes', payload)
  yield call(history.push, getRoute('WISHES.EDIT', data.wish))
  yield put(wishActions.createSuccess(data.wish))
  yield put(notificationActions.open({
    type: 'success',
    content: translateEntity('saved', 'wish.wish'),
  }))
}
function* watchUpdate({ payload }: ReturnType<typeof wishActions.update>): TSaga {
  const { data } = yield call(api.post, '/wishes', payload)
  yield put(wishActions.updateSuccess(data.wish))
  yield put(notificationActions.open({
    type: 'success',
    content: translateEntity('saved', 'wish.wish'),
  }))
}

function* watchGive({ payload }: ReturnType<typeof wishActions.give>): TSaga {
  const userId = yield select(selectAuthUserId)
  const { data } = yield call(api.put, `/wishes/${payload}/take/${userId}`, )
  yield put(wishActions.updateSuccess(data.wish))
  yield put(notificationActions.open({
    type: 'success',
    content: translateEntity('saved', 'wish.wish'),
  }))
}

function* watchUngive({ payload }: ReturnType<typeof wishActions.ungive> ): TSaga {
  const { data } = yield call(api.put, `/wishes/${payload}/taken-clear`, )
  yield put(wishActions.updateSuccess(data.wish))
  yield put(notificationActions.open({
    type: 'success',
    content: translateEntity('saved', 'wish.wish'),
  }))
}

function* watchRemove({ payload }: ReturnType<typeof wishActions.delete>): TSaga {
  yield call(api.delete, `/wishes/${payload}`)
  yield put(notificationActions.open({
    type: 'success',
    content: translateEntity('deleted', 'wish.wish'),
  }))
  yield call(history.push, COMPONENT_URLS.WISHES.LIST)
}

export function* wishesSagas(): TSaga {
  yield takeLatest(wishActions.FETCH_LIST, watchFetchList)
  yield takeLatest(wishActions.FETCH_ITEM, watchFetchItem)
  yield takeLatest(wishActions.CREATE, watchCreate)
  yield takeLatest(wishActions.UPDATE, watchUpdate)
  yield takeLatest(wishActions.GIVE, watchGive)
  yield takeLatest(wishActions.UNGIVE, watchUngive)
  yield takeLatest(wishActions.DELETE, watchRemove)
}