import type { TWish, TWishApiOpts, TWishEntity } from '../../types'

const actionTypes = {
  CREATE: 'WISHES/CREATE',
  CREATE_SUCCESS: 'WISHES/CREATE_SUCCESS',
  DELETE: 'WISHES/DELETE',
  FETCH_ITEM: 'WISHES/FETCH_ITEM',
  FETCH_LIST: 'WISHES/FETCH_LIST',
  GIVE: 'WISHES/GIVE',
  RECEIVE_ITEM: 'WISHES/RECEIVE_ITEM',
  RECEIVE_LIST: 'WISHES/RECEIVE_LIST',
  UNGIVE: 'WISHES/UNGIVE',
  UPDATE: 'WISHES/UPDATE',
  UPDATE_SUCCESS: 'WISHES/UPDATE_SUCCESS',
}

export const wishActions = {
  ...actionTypes,
  create: (payload: TWish) => ({
    payload,
    type: actionTypes.CREATE
  }),
  createSuccess: (payload: TWishEntity) => ({
    payload,
    type: actionTypes.CREATE_SUCCESS
  }),
  delete: (payload: number) => ({
    payload,
    type: actionTypes.DELETE
  }),
  fetchItem: (payload: number) => ({
    payload,
    type: actionTypes.FETCH_ITEM,
  }),
  fetchList: (payload: TWishApiOpts = {}) => ({
    payload,
    type: actionTypes.FETCH_LIST,
  }),
  give: (payload: number) => ({
    payload,
    type: actionTypes.GIVE
  }),
  receiveItem: (payload: TWishEntity) => ({
    payload,
    type: actionTypes.RECEIVE_ITEM
  }),
  receiveList: (payload: TWishEntity[]) => ({
    payload,
    type: actionTypes.RECEIVE_LIST
  }),
  ungive: (payload: number) => ({
    payload,
    type: actionTypes.UNGIVE
  }),
  update: (payload: TWishEntity) => ({
    payload,
    type: actionTypes.UPDATE
  }),
  updateSuccess: (payload: TWishEntity) => ({
    payload,
    type: actionTypes.UPDATE_SUCCESS
  }),
}
