import { all, call, spawn, put } from 'redux-saga/effects'
import authSagas from './auth.sagas'
import { friendsSagas } from './friends.saga'
import { friendsSearchSagas } from './friendsSearch.saga'
import { loginSagas } from './login.saga'
import { nearEventsSagas } from './nearEvents.saga'
import { wishesSagas } from './wishes.sagas'
import { profileSettingsSagas } from './profileSettings.sagas'
import { usersSagas } from './users.sagas'
import { profileSagas } from './profile.sagas'
import { authActions } from '#/store/actions'
import { TSaga } from '#/types'
import { localeSagas } from './locale.sagas'

export default function* rootSaga(): TSaga {
  const sagas = [
    authSagas,
    friendsSearchSagas,
    friendsSagas,
    loginSagas,
    localeSagas,
    profileSagas,
    profileSettingsSagas,
    nearEventsSagas,
    wishesSagas,
    usersSagas
  ]

  yield all(sagas.map(saga =>
    spawn(function* () {
      while (true) {
        try {
          yield call(saga)
          break
        } catch (e) {
          // @ts-ignore
          if (e?.response?.status === 403) {
            yield put(authActions.clear())
          }
      }
    }}))
  )
}