import { call, put, throttle } from 'redux-saga/effects'
import { friendsSearchActions } from '#/store/actions';
import { THROTTLE_TIMEOUT, api } from '#/config'
import { TSaga } from '#/types';

function* watchSearch({ payload: q }: ReturnType<typeof friendsSearchActions.search>): TSaga {
  const { data } = yield call(api.post, '/friends/search', { q })
  yield put(friendsSearchActions.searchSuccess(data.users))
}

export function* friendsSearchSagas(): TSaga {
  yield throttle(THROTTLE_TIMEOUT, friendsSearchActions.SEARCH, watchSearch)
}