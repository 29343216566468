import { notificationActions } from '#/store/actions';
import { TNotification } from '#/types';
import { MessageInstance } from 'antd/lib/message';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  notification: TNotification
  messageApi: MessageInstance
}

export const Notifcation = ({ messageApi, notification }: Props) => {

  const dispatch = useDispatch();

  useEffect(() => {
    messageApi.open({
      ...notification,
      onClose: () => {
        dispatch(notificationActions.clear(notification.key))
      }
    })
  }, [messageApi, notification]);

  return null;
}