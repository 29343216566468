import { call, put, takeLatest } from 'redux-saga/effects'
import { authActions } from '#/store/actions'
import { api, apiAuth, STORAGE_TOKEN_KEY } from '#/config'
import { TSaga } from '#/types';

function* watchFetchAuth(): TSaga {
  const { data } = yield call(apiAuth.get, '/profile')
  yield put(authActions.receive(data))
}

function* wathTokenReceived({ payload }: ReturnType<typeof authActions.receiveToken>): TSaga {
  sessionStorage.setItem(STORAGE_TOKEN_KEY, payload)
  api.defaults.headers.Authorization = `Bearer ${payload}`
  apiAuth.defaults.headers.Authorization = `Bearer ${payload}`

  const response = yield call(apiAuth.get, '/profile')
  if (response.status === 200) {
    yield put(authActions.receive(response.data))
  } else {
    // token seems to invalid
    yield put(authActions.clear())
  }
}

function watchClearAuth() {
  sessionStorage.removeItem(STORAGE_TOKEN_KEY);
  delete api.defaults.headers.Authorization
  delete apiAuth.defaults.headers.Authorization
}

export default function* authSagas(): TSaga {
  yield takeLatest(authActions.FETCH, watchFetchAuth)
  yield takeLatest(authActions.RECEIVE_TOKEN, wathTokenReceived)
  yield takeLatest(authActions.CLEAR, watchClearAuth)
}